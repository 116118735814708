export const PRESETS = [
  // Campanhas
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Interação', 'Topo'],
    groups: ['vitrine', 'destaque'],
    tags: [
      ['yellow', 'Campanhas'],
    ],
    title: 'Vitrine: destaque de produtos relacionados à campanha.',
    description: 'Apresente produtos relevantes associados ao motivo da visita do seu cliente.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['cart'],
    taxonomy: ['Call-to-action', 'Interação', 'Fundo'],
    groups: ['vitrine', 'destaque'],
    tags: [
      ['yellow', 'Campanhas'],
    ],
    title: 'Vitrine: crossell no carrinho com itens da campanha.',
    description: 'Lembre das oportunidades especiais associadas à campanha para usuários no carrinho de compras.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['product'],
    taxonomy: ['Call-to-action', 'Interação', 'Meio'],
    groups: ['vitrine', 'destaque'],
    tags: [
      ['yellow', 'Campanhas'],
    ],
    title: 'Vitrine: produtos relacionados e associados à campanha.',
    description: 'Nas páginas de produto, destaque itens relacionados que participam da campanha, como "Outros tênis na Black Friday".',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Interação', 'Meio'],
    groups: ['vitrine', 'destaque'],
    group: "",
    tags: [
      ['yellow', 'Campanhas'],
    ],
    title: 'Push: cupom por tempo limitado.',
    description: 'Apresente produtos relevantes associados ao motivo da visita do seu cliente.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Interação', 'Topo'],
    groups: ['push', 'destaque'],
    tags: [
      ['yellow', 'Campanhas'],
    ],
    title: 'Push: boas-vindas.',
    description: 'Apresente produtos relevantes associados ao motivo da visita do seu cliente.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Interação', 'Topo'],
    groups: ['cta', 'banner', 'destaque'],
    tags: [
      ['yellow', 'Campanhas'],
    ],
    title: 'Banner em faixa: chamada pra LP da campanha.',
    description: 'Apresente produtos relevantes associados ao motivo da visita do seu cliente.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Interação', 'Topo'],
    groups: ['banner', 'destaque'],
    tags: [
      ['yellow', 'Campanhas'],
    ],
    title: 'Banner em slide: personalize os banners para a campanha.',
    description: 'Apresente produtos relevantes associados ao motivo da visita do seu cliente.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },

  // CTAs
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Interação'],
    groups: ['cta', 'banner', 'temporizador'],
    tags: [
      ['#1a1245', 'Exemplo'],
      ['#000', 'Black Friday'],
    ],
    title: 'Banner em faixa com temporizador e call-to-action',
    description: 'Foque a atenção dos usuários no carrinho de compras prevenindo abandono ou acelerando o tempo de fechamento.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
    groups: ['cta', 'temporizador'],
    tags: [],
    title: 'Temporizador com call-to-action independente',
    description: 'Adicione gatilhos de urgência em elementos que já existem na sua página.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
    groups: ['vitrine', 'temporizador'],
    tags: [],
    title: 'Temporizador associado a vitrines',
    description: 'Utilize um temporizador em formato de faixa para adicionar urgência em vitrines.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Interação'],
    groups: ['cta', 'banner'],
    tags: [],
    title: 'Banner em Faixa com call-to-action para Landing Page',
    description: 'Conduza seus usuários para páginas especiais como Sale, Lançamentos, etc.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Interação'],
    groups: ['cta', 'banner', 'temporizador'],
    tags: [],
    title: 'Banner em Faixa com temporizador em destaque.',
    description: 'Conduza seus usuários para páginas especiais como Sale, Lançamentos, etc.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },

  // Pushes
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Push', 'Call-to-action', 'Interação', 'Carrinho'],
    groups: ['cta', 'push', 'progress'],
    tags: [],
    title: 'Push: com contador de subtotal do carrinho.',
    description: 'Ofereça benefícios baseados no subtotal do carrinho de compras, como Frete Grátis, descontos ou brindes.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
    groups: ['push'],
    tags: [],
    title: 'Push: com benefícios por tempo limitado.',
    description: 'Estimule os usuários com a urgência da promoção de itens selecionados.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
    groups: ['push'],
    tags: [],
    title: 'Push: com imagem em destaque.',
    description: 'Adicione seu criativo na estrutura do Push básico.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Avançado', 'Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
    groups: ['push'],
    tags: [],
    title: 'Push: ofertando um benefício para carrinhos selecionados.',
    description: 'Descontos ou outros estímulos comerciais direcionados para carrinhos que atendam a uma ou mais regras, como valor mínimo.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Avançado', 'Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
    groups: ['push'],
    tags: [],
    title: 'Push: com Cupom de desconto para carrinhos selecionados',
    description: 'Um push com um comportamento especial: clique para copiar o cupom.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
    groups: ['push'],
    tags: [],
    title: 'Push: com oferta de Upsell.',
    description: 'Sinalize os benefícios de uma compra maior em produtos que possuam ofertas de kits ou benefícios para compra em quantidade.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Avançado', 'Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
    groups: ['push'],
    tags: [],
    title: 'Push: com ofertas progressivas.',
    description: 'Crie uma cadeia de benefícios progressivos com pushes encadeados.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },

  // Vitrines
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
    groups: ['vitrine'],
    tags: [],
    title: 'Vitrine: Os seus favoritos.',
    description: 'Utilize a recomendação Histórido do Usuário para destacar seus produtos favoritos e reduzir o tempo de comparação nas Páginas de Produto.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
    groups: ['vitrine'],
    tags: [],
    title: 'Vitrine: Não perca de vista.',
    description: 'Mantenha os usuários conectados com seu histórico de navegação na Homepage, Catálogo e outras páginas de topo de funil.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
    groups: ['vitrine'],
    tags: [],
    title: 'Vitrine: Não esqueça deles!',
    description: 'Aumente seu ticket médio recomendando produtos relevantes para a navegação do usuário na página do carrinho.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
    groups: ['vitrine'],
    tags: [],
    title: 'Vitrine: Recomendados para Você.',
    description: 'Crie uma recomendação especial para cada produto visto pelo usuário e mantenha o engajamento em todas as páginas do site.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
    groups: ['vitrine'],
    tags: [],
    title: 'Vitrine: Outras opções.',
  description: 'Recomende produtos substitutos nas Páginas de Produto para prevenir o abandono e aumentar suas conversões.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
    groups: ['vitrine'],
    tags: [],
    title: 'Vitrine: Combina este produto com...',
    description: 'Uma vitrine feita sob medida para aumentar seu ticket médio sugerindo complementos para o produto que o usuário está vendo.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
    groups: ['vitrine'],
    tags: [],
    title: 'Vitrine: Quem comprou, comprou também.',
    description: 'Crie variações desse tipo de vitrine combinando atributos do produto sendo visto com filtros de produtos complementares.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
    groups: ['vitrine'],
    tags: [],
    title: 'Vitrine: Selecionados para você.',
    description: 'Recomende as melhores ofertas do seu catálogo combinando atributos de interesse do usuário.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
    groups: ['vitrine'],
    tags: [],
    title: 'Vitrine: Sale!',
    description: 'Destaque suas promoções com diversos filtros e condições. Explore variações com Lançamentos, Mais vendidos, etc.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
    groups: ['vitrine'],
    tags: [],
    title: 'Vitrine: Destaques da Categoria.',
    description: 'Personalize e destaque os melhores produtos das suas páginas de categoria.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  }
];

export const PRESETS_GROUPS = [
  {
    group: "destaque",
    label: "Destaques",
    order: 1,
    section: "basic_components"
  },
  {
    group: "vitrine",
    label: "Vitrines",
    order: 10,
    section: "basic_components"
  },
  {
    group: "push",
    label: "Pushes (notificações)",
    order: 20,
    section: "basic_components"
  },
  {
    group: "cta",
    label: "Call-to-actions",
    order: 30,
    section: "basic_components"
  },
  {
    group: "banner",
    label: "Banners",
    order: 40,
    section: "basic_components"
  },
  {
    group: "temporizador",
    label: "Temporizadores",
    order: 50,
    section: "basic_components"
  },
  {
    group: "progress",
    label: "Progresso",
    order: 60,
    section: "basic_components"
  }
]