import React, { useState } from 'react';
import {
  Button,
  Drawer,
  Empty,
  Form,
  Popconfirm,
  Select,
  Slider,
  Switch,
  Input
} from 'antd';

const RedirectRulesForm = ({ isAdding, onCancel, onSave, rule }) => {
  return (
    <Drawer
      title={`Regras de Redirecionamento -> ${isAdding ? 'Adicionando regra' : 'Editando'
        }`}
      placement="right"
      closable={false}
      maskClosable={false}
      visible={true}
      width={900}
      className="page-edit-wrapper"
    >
      <Form
        onFinish={onSave}
        layout="horizontal"
        labelCol={{ span: 6 }}
        requiredMark={true}
        size="large"
        style={{
          width: '90%',
          margin: '10px 50px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {rule?._id && (
          <Form.Item
            initialValue={rule._id}
            name="_id"
            // style={{ display: 'none' }}
          />
        )}

        <Form.Item style={{ alignSelf: 'flex-end' }}>
          <Popconfirm
            title="Todas as informações não salvas serão perdidas. Deseja continuar?"
            onConfirm={onCancel}
            okText="Sim"
            cancelText="Não"
          >
            <Button htmlType="button">Cancelar</Button>
          </Popconfirm>

          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: '7px' }}
          >
            Salvar
          </Button>
        </Form.Item>

        <Form.Item
          initialValue={rule?.is_active || false}
          label="Regra ativa"
          name="is_active"
        >
          <Switch
            defaultChecked={rule?.is_active || false}
            checkedChildren={'On'}
            unCheckedChildren={'Off'}
          />
        </Form.Item>

        <Form.Item
          help="Esse nome será exibido nas sugestões de pesquisa enquanto o usuário digita."
          initialValue={rule?.label || ""}
          label="Título"
          name="label"
        ><Input />
        </Form.Item>

        <Form.Item
          help="Endereço para o qual o usuário será redirecionado. Informa uma URL completa no formato https://...."
          initialValue={rule?.url || ""}
          label="URL de redirecionamento"
          name="url"
        ><Input placeholder="https://" />
        </Form.Item>

        <Form.Item
          help="Ao pesquisar por alguns destes termos, o usuário será redirecionado. Você pode informar termos com uma ou mais palavras."
          initialValue={rule?.terms || []}
          label="Termos"
          name="terms"
        > 
        <Select
            mode="tags"
            style={{ width: '100%' }}
            options={rule?.terms?.map(term => ({value: term, label: term})) || []}
          />
        </Form.Item>

        <Form.Item
          help="Contém: o termo digitado pode contar qualquer um dos termos informados na regra, aceitando outras palavras entre eles. É exatamente: o termo digitado deve ser exatamente um dos termos informados na regra. Inicia com: se o termo digitado iniciar com algum dos termos informados na regra."
          initialValue={rule?.op || "exact"}
          label="Operador de comparação"
          name="op"
        > 
        <Select
            defaultValue={rule?.op || "exact"}
            options={[
              { value: 'contains', label: 'Contém' },
              { value: 'exact', label: 'É exatamente' },
              { value: 'starts', label: 'Inicia com' },
            ]}
          />
        </Form.Item>

        <Form.Item
          help="Contém: o termo digitado pode contar qualquer um dos termos informados na regra, aceitando outras palavras entre eles. É exatamente: o termo digitado deve ser exatamente um dos termos informados na regra. Inicia com: se o termo digitado iniciar com algum dos termos informados na regra."
          initialValue={rule?.target || "_blank"}
          label="Alvo do redirecionamento"
          name="target"
        > 
        <Select
            defaultValue={rule?.target || "_blank"}
            options={[
              { value: '_blank', label: 'Mesma aba/janela' },
              { value: '_self', label: 'Nova aba/janela' }
            ]}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default RedirectRulesForm;
