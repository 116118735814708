import React from 'react';

import { Layout, PageHeader, Popconfirm, Button, message, Tooltip } from 'antd';
import PageEditComponent from './Edit';
import PageRegionsListContainer from './Regions/List';
import PageRegionEditComponent from './Regions/Edit';
import PageElementAddComponent from './Elements/Add';
import PageElementEditComponent from './Elements/Edit';
import StatusTagComponent from '../../../Common/Status/Tag';
import HelperPopup from '../../../Common/Popup/HelperPopup';

import './styles/view.css';
import {
  ControlOutlined,
  EyeOutlined,
  RightOutlined,
  DeleteOutlined,
  CopyOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import { generateLog } from '../../../../domain/Logs';

const { Content } = Layout;

export default class PageViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: { ...props.page, client_id: props.client_id },
      persist: undefined,
      error: undefined,
    };
    this.client_id = props.client_id;
    this.store = props.store;

    this.listeners = [];
  }

  componentWillReceiveProps(next) {
    this.setState(next);
  }

  componentDidMount() {
    this.bindEventListeners();
  }

  componentWillUnmount() {
    this.unbindEventListeners();
  }

  bindEventListeners() {
    this.listeners.push(
      this.store.emitter.on(
        this.store.emitter.PAGE_PERSIST_STATUS,
        this.handlePersistState.bind(this),
      ),
    );
  }

  unbindEventListeners() {
    this.listeners.map(listener => this.store.emitter.off(listener));
  }

  getPageActions() {
    const actions = [];

    actions.push(
      <HelperPopup
        url={
          'https://beonly.notion.site/Beonly-e-GA4-3242099889904a06a491d71fc6cba615'
        }
        text={'Analise no Google Analytics'}
        icon={<LineChartOutlined />}
        shape={'round'}
        style={{
          color: 'white',
          backgroundColor: 'orange',
          borderColor: 'orange',
        }}
      />,
    );

    actions.push(
      <Tooltip title="Configurações">
        <Button
          key="page:edit:settings"
          type="default"
          shape="round"
          icon={<ControlOutlined />}
          onClick={this.handleSettingsButton}
        />
      </Tooltip>,
    );

    actions.push(
      <Tooltip title="Duplicar">
        <Popconfirm
          key="page:edit:duplicate"
          title="Continuar com a cópia desta página?"
          okText="Sim"
          cancelText="Não"
          onConfirm={this.handleDuplicateButton}
        >
          <Button type="default" shape="round" icon={<CopyOutlined />} />
        </Popconfirm>
      </Tooltip>,
    );

    if (this.state.page.props.isHidden) {
      actions.push(
        <Tooltip title="Exibir">
          <Button
            key="page:edit:toggleVisibility"
            type="default"
            shape="round"
            icon={<EyeOutlined />}
            loading={this.state.loadingToggleVisibility}
            onClick={this.handleVisibilityButton}
            className={'actions_visibility-icon hidden'}
          />
        </Tooltip>,
      );
    } else {
      actions.push(
        <Tooltip title="Ocultar">
          <Popconfirm
            key="page:edit:toggleVisibility"
            title="Confirma a suspensão dessa página? Todos os componentes não estarão mais visíveis para os usuários."
            okText="Sim"
            cancelText="Não"
            onConfirm={this.handleVisibilityButton}
          >
            <Button
              type="default"
              shape="round"
              icon={<EyeOutlined />}
              loading={this.state.loadingToggleVisibility}
              className={'actions_visibility-icon visible'}
            />
          </Popconfirm>
        </Tooltip>,
      );
    }

    // default pages cannot be deleted
    if (!this.state.page.props.isDefault) {
      actions.push(
        <Tooltip title="Excluir">
          <Popconfirm
            key="page:edit:delete"
            title="Excluir uma página não pode ser desfeito. Se você deseja suspender temporariamente, experimente a ação Ocultar/Exibir."
            okText="Excluir"
            cancelText="Cancelar"
            onConfirm={this.handleDeleteButton}
          >
            <Button
              type="danger"
              shape="round"
              icon={<DeleteOutlined />}
              loading={this.state.loadingDelete}
            />
          </Popconfirm>
        </Tooltip>,
      );
    }

    return actions;
  }

  handlePersistState = (status, page, error) => {
    if (this.state.page && this.state.page._id === page._id) {
      this.setState({
        persist: status,
        error,
      });
    }
  };

  handleSettingsButton = event => {
    this.store.emitter.dispatch(
      this.store.emitter.PAGE_EDIT_START,
      this.state.page,
    );
  };

  handleDuplicateButton = event => {
    this.store.emitter.dispatch(
      this.store.emitter.PAGE_DUPLICATION_START,
      this.state.page,
    );
  };

  handleVisibilityButton = async () => {
    this.setState({
      loadingToggleVisibility: true,
    });

    const { page } = this.state;
    const now = new Date().toISOString();

    page.props.isHidden = !page.props.isHidden;
    page.props.isHidden ? (page.hidden_at = now) : (page.visible_at = now);

    page.elements.map(element => {
      if (!element.props.isHidden) {
        page.props.isHidden
          ? (element.hidden_at = now)
          : (element.visible_at = now);
      }
    });

    const savedPage = await this.store.savePage(page);

    this.setState({
      page: savedPage,
      loadingToggleVisibility: false,
    });

    const user_email = JSON.parse(
      localStorage.getItem('current_user_meta'),
    ).email;
    const user_id = JSON.parse(localStorage.getItem('current_user_meta'))._id;
    const resource = `${page.context.name}-${page.props.label}`;

    let pageStatus = '';

    if (page.props.isHidden) {
      pageStatus = 'suspensa';
    } else {
      pageStatus = 'publicada';
    }

    const log = {
      app_location: 'Beon Studio',
      tenant_id: this.client_id,
      time_stamp: new Date().toJSON(),
      user_email,
      user_id,
      resource,
      action_type: `Visibilidade da página alterada para ${pageStatus}`,
    };

    generateLog(log).then(result => {
      if (result.status !== 201) {
        message.error('Falha ao gerar log');
      }
    });
  };

  handleDeleteButton = async event => {
    this.setState({
      loadingDelete: true,
    });

    const deletedPage = await this.store.deletePage(
      this.state.page.client_id,
      this.state.page._id,
    );

    this.setState({
      page: deletedPage,
      loadingDelete: false,
    });

    const user_email = JSON.parse(
      localStorage.getItem('current_user_meta'),
    ).email;
    const user_id = JSON.parse(localStorage.getItem('current_user_meta'))._id;
    const pageRegion = this.state.page.context.name;
    const pageName = this.state.page.props.label;

    const log = {
      app_location: 'Beon Studio',
      tenant_id: this.client_id,
      time_stamp: new Date().toJSON(),
      user_email,
      user_id,
      resource: `${pageRegion}-${pageName}`,
      action_type: 'Página deletada',
    };

    const logResult = await generateLog(log);

    if (logResult.status !== 201) {
      message.error('Falha ao gerar log');
    }
  };

  getPersistStatus() {
    const { persist } = this.state;
    let status;

    switch (persist) {
      case 'START':
        status = 'Salvando alterações...';
        break;
      case 'FAIL':
        status = 'Falha ao salvar as alterações.';
        break;
      case 'DONE':
        status = 'Todas as alterações foram salvas.';
        break;
      default:
        status = '';
        break;
    }

    return <>{status}</>;
  }

  render() {
    return (
      <Content style={{ minHeight: '100%' }}>
        <PageHeader
          backIcon={false}
          title={
            <span>
              <span>{this.state.page.context.label}</span>
              <RightOutlined style={{ padding: '0 10px' }} />
              <span>{this.state.page.props.label}</span>
            </span>
          }
          tags={[
            <StatusTagComponent
              key="page:status"
              status={this.state.page.props.status}
            />,
            <span key="page:persistent">{this.getPersistStatus()}</span>,
          ]}
          extra={this.getPageActions()}
        />

        <PageRegionsListContainer
          store={this.store}
          regions={this.state.page.regions}
          elements={this.state.page.elements}
          client_id={this.client_id}
        />

        <PageEditComponent client_id={this.client_id} store={this.store} />
        <PageRegionEditComponent
          store={this.store}
          client_id={this.client_id}
        />
        <PageElementAddComponent store={this.store} />
        <PageElementEditComponent
          client_id={this.client_id}
          store={this.store}
        />
      </Content>
    );
  }
}
