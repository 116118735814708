import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import LoadingComponent from '../../../../../Common/Loading/Loading';
import RedirectRulesForm from '../../../../../Common/Search/RedirectRules/Form';
import RedirectRulesHeader from '../../../../../Common/Search/RedirectRules/Header';
import SearchApiConsumer from '../../../../../../core/search/rest/api';
import RedirectRuleComponent from '../../../../../Common/Search/RedirectRules';

const RedirectRules = ({ loadForm, storedConfig }) => {
  const { client_id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [showRulesForm, setShowRulesForm] = useState(false);
  const [currentFormRule, setCurrentFormRule] = useState({});
  const [isAdding, setIsAdding] = useState(false);
  const [rules, setRules] = useState([]);

  const patchRulesInSearchApi = async ( rules, suggest ) => {
    try {
      setIsLoading(true);

      const payload = {
        search: {
          backend: {
            redirects: {
              rules,
              suggest
            },
          },
        },
      };

      await SearchApiConsumer.savePage(payload, client_id);
      loadForm();

      message.success('Informações salvas com sucesso!');
    } catch (e) {
      message.error(
        'Ocorreu um erro ao salvar as informações. Por favor, tente novamente.',
      );
      console.warn('Patch redirect rules in search api failed with', e);
    } finally {
      setShowRulesForm(false);
      setIsLoading(false);
    }
  };

  const onSave = async ({ _id, is_active, label, url, terms, op, target }) => {
    const newRule = {
      _id: _id ?? crypto.randomUUID(),
      is_active, label, url, terms, op, target
    };

    const formattedRules = rules
      .filter(r => r._id !== _id)
      .map(rule => ({
        ...rule
      }));

    const newRules = [...formattedRules, newRule];

    await patchRulesInSearchApi(newRules);
  };

  const onCancel = () => {
    setShowRulesForm(false);
    setCurrentFormRule({});
  };

  const editRule = async id => {
    const rule = rules.find(({ _id }) => _id === id);
    setCurrentFormRule(rule);
    setIsAdding(false);
    setShowRulesForm(true);
  };

  const deleteRule = async ({ _id }) => {
    const newRules = rules
      .filter(r => r._id !== _id)
      .map(rule => ({
        ...rule
      }));

    await patchRulesInSearchApi(newRules);
  };

  useEffect(() => {
    const redirectRules =
      storedConfig?.search?.backend?.redirects?.rules;

    if (redirectRules) {
      const normalizedRules = redirectRules.map(rule => {
        return { ...rule };
      });

      setRules(normalizedRules);
    }
  }, [storedConfig]);

  if (isLoading) return <LoadingComponent />;

  return (
    <>
      <Button
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        style={{ margin: '20px 0' }}
        onClick={() => {
          setIsAdding(true);
          setShowRulesForm(true);
        }}
      >
        Adicionar regra
      </Button>

      {rules.length > 0 && <RedirectRulesHeader />}

      {rules.map(rule => (
        <RedirectRuleComponent
          key={rule._id}
          rule={rule}
          editRule={editRule}
          deleteRule={deleteRule}
        />
      ))}

      {showRulesForm && (
        <RedirectRulesForm
          isAdding={isAdding}
          onCancel={onCancel}
          onSave={onSave}
          rule={currentFormRule}
        />
      )}
    </>
  );
};

export default RedirectRules;
